export const allSocialMedias = [
    {
        name: 'Bilibili',
        link: 'https://space.bilibili.com/388230358',
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/spiral_dreams_band?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
    },
    {
        name: 'YouTube',
        link: 'https://www.youtube.com/@spiraldreamsband',
    },
    {
        name: '小红书',
        link: 'https://www.xiaohongshu.com/user/profile/656c403a00000000190126c7',
    },
];
