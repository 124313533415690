export const allMembers = [
    {
        name: 'Han',
        image: 'han.jpeg',
        position: 'Guitar',
    },
    {
        name: 'Linus',
        image: 'linus.jpeg',
        position: 'Bass',
    },
    {
        name: 'Diana',
        image: 'diana.jpeg',
        position: 'Drums',
    },
    {
        name: 'Ken',
        image: 'ken.jpeg',
        position: 'Vocal',
    },
    {
        name: 'Lemon',
        image: 'lemon.jpeg',
        position: 'Vocal',
    },
    {
        name: 'Ming',
        image: 'ming.jpeg',
        position: 'Guitar',
    },
];